.card {
  border: 1px solid aqua;
  box-shadow: 2px 2px 2px aqua;
  position: relative;
  z-index: 2;
  background: white;
  margin-bottom: 20px;
  /* padding: 20px; */
  border-radius: 10px;
  width: 100%;
  transition: all 400ms ease-in;
  &:hover {
    box-shadow: 5px 5px 5px aqua;
  }
  &__img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    transition: all 400ms ease-in;
    &__up {
      transform: rotate(180deg) translateY(-50%);
      transform-origin: top;
    }
    &__down {
      transform: rotate(0deg) translateY(-50%);
      transform-origin: top;
    }
  }
  &__p {
    background-color: aqua;
    z-index: 1;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
    /* top: -5px; */
    padding: 20px 10px 10px 10px;
    /* height: 0; */
    line-height: 1.4;
    /* &__show {
      opacity: 1;
      height: 145px;
      margin-bottom: 20px;
    } */
    /* &__about {
      &__show {
        opacity: 1;
        height: 195px;
        margin-bottom: 20px;
      }
      &__hide {
        opacity: 0;
        height: 0;
      }
    } */
    /* &__hide {
      opacity: 0;
      height: 0;
    } */
    /*  &__content {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    } */
  }
}

@media screen and (max-width: 1200px) {
  .card {
    &__p {
      &__show {
        height: 160px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .card {
    &__p {
      &__show {
        height: 120px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .card {
    &__p {
      &__show {
        height: 140px;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .card {
    &__p {
      &__show {
        height: 160px;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .card {
    &__h3 {
      max-width: 90%;
    }
    &__p {
      &__show {
        height: 190px;
      }
    }
  }
}

@media screen and (max-width: 325px) {
  .card {
    &__h3 {
      max-width: 90%;
    }
    &__p {
      &__show {
        height: 235px;
      }
    }
  }
}
