.home {
  position: relative;
  z-index: 690;
  &__bg {
    height: 500px;
    width: 100%;
    margin-top: 84px;
    position: relative;
    &__img {
      object-fit: cover;
      width: 100%;
      height: 500px;
    }
  }
  &__goal {
    padding-bottom: 40px;
    margin: 0px 10% 50px 10%;
    &__h2 {
      color: black;
      padding-top: 80px;
      width: 431px;
      margin: 0px auto 30px auto;
    }
    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 40px;
      column-gap: 50px;
    }
  }
  &__what {
    background-color: rgba(0, 0, 0, 0.04);
    padding: 0px 0 50px 0;
    &__container {
      margin: 0 10% 0 10%;
      &__h2 {
        color: black;
        padding-top: 80px;
        width: 353px;
        margin: 0px auto 30px auto;
      }
      &__content {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        &__card {
          min-height: 100%;
          border-radius: 10px;
          box-shadow: 0px 0px 14px 5px aqua;
          display: block;
          overflow: hidden;
          &__img {
            min-height: 100%;
            display: inline-block;
            vertical-align: middle;
            transform: scale3d(1, 1, 1);
            transition: transform 400ms ease-in-out;
            object-fit: cover;
            height: auto;
            border-radius: 10px;
            cursor: pointer;
            width: 100%;
            min-width: 400px;
            &:hover {
              transform: scale(1.1);
              transition: transform 400ms ease-in-out;
            }
          }
        }

        &__div {
          border-radius: 20px;
          color: black;
          margin-left: 100px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          flex: 1;
          &__p {
            line-height: 1.4;
          }
          &__ul {
            padding: 21px 0px 21px 40px;
          }
        }
      }
    }
  }
  &__who {
    padding: 0px 0 50px 0;
    margin: 0 10% 0 10%;
    &__h2 {
      color: black;
      padding-top: 80px;
      width: 353px;
      margin: 0px auto 30px auto;
    }
    &__p {
      line-height: 1.4;
      margin-bottom: 30px;
    }
    &__content {
      display: flex;
      justify-content: space-between;
      &__div {
        flex: 1;
        border-radius: 20px;
        color: black;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-right: 80px;
        &__card {
          min-height: 100%;
          border-radius: 10px;
          box-shadow: 0px 0px 14px 5px aqua;
          display: block;
          overflow: hidden;
          &__img {
            min-height: 100%;
            display: inline-block;
            vertical-align: middle;
            transform: scale3d(1, 1, 1);
            transition: transform 400ms ease-in-out;
            object-fit: cover;
            height: auto;
            cursor: pointer;
            width: 100%;
            min-width: 400px;
            &:hover {
              transform: scale(1.1);
              transition: transform 400ms ease-in-out;
            }
          }
        }
        &__h3 {
          margin-bottom: 20px;
        }
        &__p {
          line-height: 1.4;
        }
      }
    }
  }
}

.notfound {
  min-height: calc(100vh - 435px);

  &__container {
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0px 0px 14px 5px red;
    margin: 200px 10% 50px 10%;
    &__h1 {
      padding-top: 20px;
    }
    &__h2 {
      margin-bottom: 20px;
    }
    &__article {
      &__img {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
      &__p {
        text-align: center;
        margin-top: 30px;
      }
      &__box {
        margin-top: 20px;
        display: flex;
        justify-content: space-around;
        margin: 40px 0 40px 0;
        &__link {
          padding: 10px;
          background-color: orange;
          cursor: pointer;
          border-radius: 10px;
          color: white;
          transform: scale(1);
          transition: all 0.3s;
          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1800px) {
  .home {
    &__goal {
      &__container {
        grid-template-columns: 1fr 1fr;
        row-gap: 20px;
        column-gap: 50px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .home {
    &__goal {
      &__container {
        row-gap: 10px;
        column-gap: 20px;
      }
    }
  }
}

@media screen and (max-width: 1115px) {
  .home {
    &__goal {
      &__container {
        grid-template-columns: 1fr;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .home {
    &__who {
      &__content {
        flex-direction: column;
        &__div {
          margin-right: 0px;
          margin-bottom: 30px;
          &__h3 {
            margin-bottom: 20px;
            width: 500px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
    &__what {
      &__container {
        &__content {
          flex-direction: column-reverse;
          &__div {
            margin-left: 0px;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .notfound {
    &__container {
      margin: 160px 10% 50px 10%;
    }
  }
  .home {
    &__bg {
      height: 400px;
      margin-top: 75px;
      &__img {
        height: 400px;
      }
      &__container {
        padding: 40px 0 40px 40px;
      }
    }
    &__what {
      &__container {
        margin: 0 5% 0 5%;
      }
    }
    &__who {
      margin: 0 5% 0 5%;
      &__content {
        &__div {
          &__h3 {
            width: 390px;
          }
        }
      }
    }
    &__goal {
      margin: 0 5% 0 5%;
      &__container {
        grid-template-columns: 1fr;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .home {
    &__goal {
      &__h2 {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .home {
    &__bg {
      margin-top: 70px;
      &__container {
        padding: 40px 20px 40px 20px;
        width: 100%;
      }
    }
    &__who {
      &__h2 {
        padding-top: 80px;
        margin-bottom: 30px;
        width: 280px;
      }
      &__content {
        &__div {
          &__h3 {
            width: 280px;
          }
        }
      }
    }
    &__what {
      padding: 0px 0 50px 0;
      &__container {
        &__h2 {
          padding-top: 80px;
          margin-bottom: 30px;
          width: 280px;
        }
      }
    }
    &__goal {
      padding-bottom: 20px;
      &__h2 {
        padding-top: 80px;
        margin-bottom: 30px;
        width: 195px;
      }
    }
  }
}
