.btn {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  cursor: pointer;
  padding: 10px;
  border: 2px solid aqua;
  color: black;
  border-radius: 10px;
  background-color: white;
  transition: all 400ms;
  &:hover {
    color: white;
    background-color: aqua;
  }
}
